<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">รายการแบนเนอร์อะคาเดมี่</h1>
        </b-col>
        <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="ชื่ออะคาเดมี่"
                v-model="filter.Search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
            <router-link to="/academy-banner/details/0">
              <b-button class="btn-main">สร้างแบนเนอร์</b-button>
            </router-link>
          </div>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white p-3">
        <b-row class="no-gutters align-items-center">
          <b-col md="6">
            ลิ้งค์วิดีโอหน้าอะคาเดมี่ <span class="text-black-50 pl-1">(รองรับลิ้งค์จาก YouTube)</span>
          </b-col>
          <b-col md="6" class="text-md-right">
            <span class="mr-md-2 text-dark">{{academyLinkUrl}}</span>
            <b-button
              variant="link"
              class="text-dark p-0"
              @click="$refs.modalEditLink.show()"
            >
              แก้ไขลิ้งค์
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
              class="table-list"
            >
              <template v-slot:cell(imageUrl)="data">
                <div
                  class="image"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imageUrl + ')',
                  }"
                  v-if="data.item.isVideo != true"
                ></div>
                <div
                  v-else
                  class="embed-responsive embed-responsive-16by9 banner-video"
                >
                  <video class="w-100 videos" controls>
                    <source :src="data.item.imageUrl" type="video/mp4" />
                  </video>
                </div>
              </template>
              <template v-slot:cell(sortOrder)="data">
                <div v-if="data.item.sortOrder == 0">-</div>
                <div v-else>{{ data.item.sortOrder }}</div>
              </template>
              <template v-slot:cell(updatedTime)="data">
                <span>{{
                  new Date(data.item.updatedTime) | moment($formatDate)
                }}</span>
              </template>
              <template v-slot:cell(display)="data">
                <div v-if="data.item.display == true" class="text-success">
                  ใช้งาน
                </div>
                <div v-else class="text-danger">ไม่ใช้งาน</div>
              </template>
              <template v-slot:cell(id)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/academy-banner/details/' + data.item.id">
                    <b-button variant="link" class="text-dark px-1 py-0">
                      แก้ไข
                    </b-button>
                  </router-link>
                  <b-button
                    variant="link"
                    class="text-dark px-1 py-0"
                    @click="openModalDelete(data.item)"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="
              form-inline
              justify-content-center justify-content-sm-between
            "
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.pageNo"
                :total-rows="rows"
                :per-page="filter.perpage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.perpage"
              @change="hanndleChangeperpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalEditLink
      ref="modalEditLink"
      @confirm="updateLink"
      :url="academyLinkUrl"
    />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalEditLink from "./components/ModalEditLink";
export default {
  name: "academyBanner",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalEditLink
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      id: "",
      requestDeleteBanner: {
        id: null,
      },
      fields: [
        {
          key: "imageUrl",
          label: "ภาพประกอบ",
          class: "w-200",
        },
        {
          key: "name",
          label: "ชื่อ",
          class: "w-100px",
        },
        {
          key: "sortOrder",
          label: "ลำดับการแสดง",
          class: "w-200",
        },
        {
          key: "updatedTime",
          label: "วันที่ทำการสร้าง",
          class: "w-100px",
        },
        // {
        //   key: "isVerify",
        //   label: "ไฮไลท์",
        //   class: "w-100px",
        // },
        {
          key: "display",
          label: "สถานะการแสดง",
          class: "w-100px",
        },
        {
          key: "id",
          label: "",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        search: "",
        status: [],
        pageNo: 1,
        perpage: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      isDisable: false,
      timer: null,
      academyLinkUrl: ""
    };
  },
  created: async function () {
    await this.getVdoList();
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getVdoList: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/academy/AcademyLinkUrl`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.academyLinkUrl  = resData.detail;
      }
    },
    getList: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/bannerAcademy/list`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;

        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    getDataByStatus(status) {
      this.filter.OverView = status;
      this.getList();
    },
    pagination(Page) {
      window.scrollTo(0, 0);
      this.filter.pageNo = Page;
      this.getList();
    },
    hanndleChangeperpage(value) {
      this.filter.pageNo = 1;
      this.filter.perpage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.pageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getList();
    },
    hanndleStatusList(value) {
      this.filter.pageNo = 1;
      this.filter.statusId = value;
      this.getList();
    },
    openModalDelete(value) {
      this.requestDeleteBanner.id = value.id;
      this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/bannerAcademy/remove/${this.requestDeleteBanner.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.filter.pageNo = 1;
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    updateLink: async function (value) {
      this.academyLinkUrl = value;
      let body = { value }
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/academy/AcademyLinkUrl/save`,
        null,
        this.$headers,
        body
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        await this.$refs.modalEditLink.hide();
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    }
  },
};
</script>

<style scoped>
.image {
  width: 100%;
  padding-top: 42.9%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-video::before {
  padding-top: 42.9%;
}
</style>
