<template>
  <div class="d-none">
    <b-modal v-model="showModal" ref="confirmCancelModal" centered hide-header hide-footer>
      <div class="modal-header">
        <h4>แก้ไขลิ้งค์วิดีโอสำหรับหน้าอะคาเดมี่</h4>
        <button type="button" aria-label="Close" class="close" @click="hide">×</button>
      </div>
      <div>
        <InputText
          textFloat="ลิ้งค์วิดีโอ"
          placeholder="https://www.youtube.com/embed/xxxxxxx"
          type="text"
          name="url"
          v-model="urlYoutube"
          @onKeyup="replaceLink"
          @input="replaceLink"
        />
        <b-aspect aspect="16:9" v-if="urlYoutube">
          <iframe width="100%" height="100%" :src="urlYoutube" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </b-aspect>
      </div>
      <div class="form-inline justify-content-center mt-3">
        <b-button class="mr-2 btn-modal btn-details-set btn-cancel btn-secondary" @click="hide">ย้อนกลับ</b-button>
        <b-button class="ml-2 btn-modal" variant="main" @click="$emit('confirm', urlYoutube)">บันทึก</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";

export default {
  props: {
    url: {
      required: false,
      type: String
    },
  },
  components: {
    InputText,
  },
  data() {
    return {
      showModal: false,
      urlYoutube: ""
    };
  },
  methods: {
    show() {
      this.urlYoutube = this.url;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    replaceLink(){
      this.urlYoutube = this.urlYoutube.replace("https://youtu.be", "https://www.youtube.com/embed");
    }
  }
};
</script>

<style scoped>
.modal-header {
  border-bottom: 0 !important;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}
.text-msg {
  font-size: 2rem;
  color: #4a4a4a;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.2rem;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
}
.modal-body {
  margin: auto;
  text-align: center;
  padding: 0;
}
.btn-modal {
  width: 120px !important;
  min-width: 120px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>